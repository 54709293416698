<template>
  <div class="container">
    <el-card>
      <!-- <div class="employee_head">批量生成员工</div> -->
      <el-page-header @back="cancel" content="批量生成员工">
      </el-page-header>
    </el-card>
    <el-card>
      <div class="employee">
        <el-form :model="employForm">
          <div class="curren_bo">
            <div class="curren_fp_lx">
              <div class="item-num">
                <span class="desc">公司名称</span>
                <el-select v-model="company_id" disabled>
                  <el-option v-for="(item,index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </div>
              <div class="item-num">
                <span class="desc">生成数量：</span>
                <el-input
                  v-model="employForm.num"
                  type="number"
                  :min="1"
                ></el-input>
              </div>
              <div class="item-num">
                <span class="desc">生成密码：</span>
                <el-input
                  v-model="employForm.password"
                  type="password"
                  :min="1"
                ></el-input>
              </div>
              <div class="item-num">
                <span class="desc">密码格式：</span>
                <el-radio-group v-model="employForm.password_type">
                  <el-radio :label="1">数字</el-radio>
                  <el-radio :label="2">字母</el-radio>
                  <el-radio :label="3">数字字母混合</el-radio>
                </el-radio-group>
              </div>
              <div class="item-num">
                <span class="desc">员工生成规则：</span>
                <el-checkbox
                  v-for="(item, index) in employForm.item_no_rules"
                  :key="index"
                  @change="handleRulesChange($event, item, index)"
                  v-model="employForm.item_no_rules[index]['selected']"
                  >{{ item.name }}</el-checkbox
                >
              </div>
              <div class="curren_tab">
                <el-table :data="employForm.item_no_table" style="width: 100%">
                  <el-table-column prop="date" label="排序" width="180">
                    <template>
                      <i class="touch"></i>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="内容" width="180">
                    <template slot-scope="scope">
                      <el-form-item>
                        <span class="span" v-if="scope.row.type != 'diy'">{{
                          scope.row.name
                        }}</span>
                        <el-input
                          class="bdnoe"
                          v-model="scope.row.name"
                          placeholder="请输入内容"
                          v-else
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="content" label="内容">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="`item_no_table[${scope.$index}]['content']`"
                        :rules="{
                          required: true,
                          validator: (rules, value, callback) =>
                            valid(rules, scope.row, callback),
                          trigger: 'blur',
                        }"
                      >
                        <!-- <el-input
                          :disabled="scope.row.type == 'timestamp'"
                          v-model="scope.row.content"
                          placeholder="请输入内容"
                        >
                          <span slot="append" v-if="scope.row.name == '随机数'"
                            >位</span
                          >
                        </el-input> -->
                        <template v-if="scope.row.name == '随机数'">
                          <el-select v-model="scope.row.content" placeholder="请选择随机数位数">
                            <el-option :label="3" :value="3"></el-option>
                            <el-option :label="4" :value="4"></el-option>
                            <el-option :label="5" :value="5"></el-option>
                          </el-select>
                        </template>
                       <template v-else>
                        <el-input
                          :disabled="scope.row.type == 'timestamp'"
                          v-model="scope.row.content"
                          placeholder="请输入内容"
                        >
                        </el-input>
                       </template>
                        <i
                          class="el-icon-delete"
                          @click="deleporit(scope.row)"
                        ></i>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-button
                icon="el-icon-circle-plus-outline"
                @click="handleIncrement"
                v-show="
                  employForm.item_no_rules &&
                  employForm.item_no_rules.find((item) => item.type == 'diy')
                    ? employForm.item_no_rules.find(
                        (item) => item.type == 'diy'
                      ).selected &&
                      employForm.item_no_table.filter(
                        (item) => item.type == 'diy'
                      ).length == 0
                    : false
                "
                >新增</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </el-card>
    <diy-footer-button>
      <el-button type="primary" @click="edit">提交</el-button>
      <el-button @click="cancel">取消</el-button>
    </diy-footer-button>
  </div>
</template>
<script>
import { Message } from "element-ui";
import Sortable from "sortablejs";
export default {
  data() {
    return {
      employForm: {
        num: 1,
        item_no_rules: [
          {
            type: "item_no_prefix",
            selected: true,
            name: "员工前缀",
          },
          {
            type: "random",
            selected: true,
            name: "随机数",
          },
          {
            type: "diy",
            selected: false,
            name: "自定义",
          },
        ],
        item_no_table: [
          {
            type: "item_no_prefix",
            name: "员工前缀",
            content: "cs2_",
            id: 1,
          },
          {
            type: "random",
            name: "随机数",
            content: 3,
            id: 3,
          },
        ],
        password_type:1,
        password:'',
      },
      company_id:this.$route.query.company_id,
      activity_id:this.$route.query.active_id||this.$route.query.activity_id||'0',
      companyList:[],
    };
  },
  created(){
    this.getCompany()  
  },
  mounted() {
    this.handleMove();
  },
  methods: {
    handleRulesChange(flag, item, index) {
      console.log(flag, item, index);
      // 如果取消了前面三种规格，则需要取消表格中对应的数据
      if (flag) {
        //选中
        if (item.type != "diy") {
          //排除自定，自定需要点击添加按钮新增
          this.employForm.item_no_table.splice(index, 0, item);
        }
      } else {
        // 找到对应数据并且移除
        const index = this.employForm.item_no_table.findIndex(
          (ite) => ite.type == item.type
        );
        if (index != -1) {
          this.employForm.item_no_table.splice(index, 1);
        }
      }
    },
    // 新增
    handleIncrement() {
      const increInfo = {
        type: "diy",
        name: "",
        content: "",
        id:
          this.employForm.item_no_table[
            this.employForm.item_no_table.length - 1
          ].id + 1,
      };
      // 限制添加一个（）
      if (
        this.employForm.item_no_table.filter((item) => item.type == "diy")
          .length == 0
      ) {
        this.employForm.item_no_table.push(increInfo);
      } else {
        return Message.warning("只能添加一个自定员工前缀!");
      }
    },
    valid(rules, { name, content, type }, callback) {
      if (content == "") {
        callback();
      }
      if (content.length > 5 && type != "random") {
        callback(
          new Error(
            `${name}内容长度不超过${
              type == "item_no_prefix" ? 5 : type == "diy" ? 5 : 5
            }个字!`
          )
        );
      }
      if (type == "random" && Number(content) > 5) {
        callback(new Error(`${name}位数最大值不可超过5!`));
      }
      callback();
    }, // 商品货号数据删除
    deleporit(row) {
      // 通过id找到数据并移除
      const index = this.employForm.item_no_table.findIndex(
        (item) => item.id == row.id
      );
      // 时间戳和随机数至少有一个存在不能删除
      if (index != -1) {
        if (
          (row.type == "random" &&
            this.employForm.item_no_table.some(
              (item) => item.type == "timestamp"
            )) ||
          row.type != "random"
        ) {
          const deleteRow = this.employForm.item_no_table.splice(index, 1);
          // 根据类型去header中设置seleced
          const { type } = deleteRow[0];
          if (type) {
            this.employForm.item_no_rules.find(
              (ite) => ite.type == type
            ).selected = false;
          }
        } else {
          return Message.warning("随机数必须存在!");
        }
      }
    },
    handleMove() {
      const _this = this;
      const el = document.querySelector(".el-table__body-wrapper tbody");
      Sortable.create(el, {});
    },
    cancel() {
      this.$router.go(-1);
    },
    edit() {
      let that = this;
      if (!this.employForm.num) {
        that.$message({
          type: "warning",
          message: "请填写生成数量",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      let prefix = this.employForm.item_no_table.findIndex(
        (item) => item.type == "item_no_prefix"
      );
      let random_length = this.employForm.item_no_table.findIndex(
        (item) => item.type == "random"
      );
      let suffix = this.employForm.item_no_table.findIndex(
        (item) => item.type == "diy"
      );
      let params = {
        num: this.employForm.num,
        company_id: this.company_id,
        password:this.employForm.password,
        password_type:this.employForm.password_type,
      };
      if (prefix == 0 || prefix > 0) {
        params.prefix = this.employForm.item_no_table[prefix].content;
      }
      if (random_length == 0 || random_length > 0) {
        params.random_length = Number(
          this.employForm.item_no_table[random_length].content
        );
      } else {
        that.$message({
          type: "warning",
          message: "请选择随机数",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      if (suffix == 0 || suffix > 0) {
        params.suffix = this.employForm.item_no_table[suffix].content;
      }
      this.$post(this.$apis.batchCreate, params)
        .then((res) => {
          if (res.code == 200) {
            that.$message({
                type: "success",
                message: "操作成功",
                showClose: true,
                offset: 200,
                duration: 1000,
            });
            setTimeout(()=>{
                that.$router.go(-1);
            },1500)
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    getCompany(){
      let that=this;
      this.$get(this.$apis.allCompany).then((res)=>{
        if(res.code==200){
          that.companyList=res.data.list;
          that.company_id=Number(that.$route.query.company_id)|| 0;
        }else{
          this.$message.error(res.message);
        }
      }).catch((err)=>{
        this.$message.error(err.message);
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .employee_head {
    font-size: 16px;
    font-weight: 600;
  }
  .employee {
    .curren_bo {
      .curren_fp_lx {
        margin-bottom: 0px;
        // margin-left: 60px;
        .item-num {
          margin-bottom: 20px;
        }
        .item-num:last-child {
          margin-bottom: 0;
        }
        & .inventory {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 24px;

          & /deep/.el-input {
            // width: 356px;
            // height: 40px;
            // background: #FFFFFF;
            // border-radius: 4px;
          }
        }

        & .desc {
          display: inline-block;
          width: 130px;
          text-align: right;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 8px;
        }

        & /deep/.el-checkbox {
          margin-left: 0 !important;
        }

        .curren_tab {
          // position: relative;
          // width: 700px;
          // margin-left: 130px;
          // margin-top: 24px;
          & /deep/ tr th:nth-of-type(2) {
            padding: 0 15px;
          }

          & /deep/.el-table .el-table__row .el-table__cell {
            padding: 18px 0 0 !important;
          }

          & /deep/.touch {
            display: inline-block;
            width: 32px;
            height: 32px;
            background: url("~@/assets/images/touch.svg") center center
              no-repeat;
            background-size: 100% 100%;
            cursor: move;
          }

          & /deep/tr td .cell {
            & .el-form-item__content {
              display: flex;
              align-items: center;
            }

            & .span {
              padding: 0 15px;
            }

            & .el-icon-delete {
              width: 16px;
              height: 16px;
              margin-left: 10px;
              cursor: pointer;
              display: none;

              &:hover {
                color: var(--fontColor);
              }
            }
          }

          & /deep/.el-table__body tbody tr:hover .el-icon-delete {
            display: block;
          }

          .bdnoe/deep/ .el-input__inner {
            border: none;
          }

          .el-input {
            position: relative;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            // width: 290px;
          }

          position: relative;
          width: 700px;
          margin-left: 130px;
          margin-top: 24px;

          /deep/.el-table
            .el-table__header-wrapper
            .el-table__header
            thead
            tr
            th {
            background-color: #f9f9fc !important;
          }

          .curren_tab_aicon {
            position: absolute;
            width: 38px;
            height: 38px;
            background: #f5f6fa;
            border-radius: 0px 2px 2px 0px;
            border: 1px solid #dddfe6;
            right: 10px;
            top: 190px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 38px;
            text-align: center;
          }

          /deep/.el-table th.el-table__cell > .cell {
            display: inline-block;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            position: relative;
            vertical-align: middle;
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
          }
        }

        /deep/ .el-button {
          width: 100px;
          margin-left: 130px;
          margin-top: 16px;
          height: 38px;
          font-size: 12px;
          color: #000d9a !important;
          background: #ffffff;
          border-radius: 3px;
        }
      }
    }
  }
}
.employee_nav{
    margin-top: 10px;
    font-size: 16px;
  }
</style>